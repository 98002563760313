// Default style for links without underline
export const linkStyle = {
    textDecoration: 'none',
    color: '#0d6efd',
    fontWeight: 'bold'
};

// Handler for mouse enter (hover)
export const handleMouseEnter = (e) => {
    e.target.style.color = '#0a58ca';
    e.target.style.textDecoration = 'underline';
};

// Handler for mouse leave
export const handleMouseLeave = (e) => {
    e.target.style.color = '#0d6efd';
    e.target.style.textDecoration = 'none';
};